import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AuthDialogSideBoat from 'src/assets/images/authDialogSideBoat.png'
import LeftArrowBlack from 'src/assets/images/leftArrowBlack.svg'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import FormLabel from '@mui/material/FormLabel'
import { Typography, IconButton, InputAdornment, Grid } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { useAuth } from 'src/hooks/useAuth'

const ImageWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${AuthDialogSideBoat})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '348px', // Height for xs screens
  filter: 'contrast(1.1) brightness(1.0)',
  [theme.breakpoints.up('sm')]: {
    height: '70vh' // Full height for larger screens
  }
}))

const BackButton = styled(Button)(({ theme }) => ({
  padding: '10px 20px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #868F99',
  position: 'sticky',
  top: '25px',
  left: '25px'
}))

const TitleTypography = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().required('Password is required')
})

function LoginForm({ open, toggle, email: initialEmail, openForgetPassword, openOtp }) {
  const auth = useAuth()
  const translation = useCustomTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: initialEmail || ''
    }
  })

  useEffect(() => {
    if (initialEmail) {
      setValue('email', initialEmail)
    }
  }, [initialEmail, setValue])

  const handleClick = () => {
    toggle()
    openForgetPassword(getValues('email'))
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prev => !prev)
  }

  const onSubmit = data => {
    setLoading(true)
    const payload = {
      email: data.email,
      password: data.password
    }

    axiosInstance
      .post(ApiEndPoints.AUTH.login, payload)
      .then(response => response.data)
      .then(response => {
        console.log('responseFORMAPI', response)
        if (response && response.user) {
          const { user, token, message } = response

          if (user.account_status === 'active') {
            toastSuccess(message)
            toggle()
            reset()
            auth.login({ user, token })
          } else if (user.account_status === 'pending') {
            openOtp(data.email)
          } else {
            throw new Error('Unexpected account status')
          }
        } else {
          throw new Error('Unexpected response structure')
        }
      })
      .catch(error => {
        toastError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleBackButtonClick = () => {
    reset()
    toggle()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleBackButtonClick}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            borderRadius: '30px',
            overflow: 'hidden'
          }
        }}
      >
        <Grid container sx={{ overflowY: { xs: 'auto', xl: 'hidden' } }}>
          <Grid item xs={12} sm={6}>
            <ImageWrapper>
              <BackButton onClick={handleBackButtonClick}>
                <img src={LeftArrowBlack} alt='Back Icon' />
              </BackButton>
            </ImageWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', p: { xs: 3, md: 10 } }}
          >
            <Container>
              <Box paddingBottom={'20px'}>
                <TitleTypography>Login</TitleTypography>
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
                <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
                  <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <FormLabel htmlFor='email' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.email.label')}
                      </FormLabel>
                      <Controller
                        name='email'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoFocus
                            id='email'
                            placeholder={translation.translate('form.email.placeholder')}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <FormLabel htmlFor='password' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.password.label')}
                      </FormLabel>
                      <Controller
                        name='password'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id='password'
                            placeholder={translation.translate('form.password.placeholder')}
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ''}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleTogglePasswordVisibility}
                                    edge='end'
                                  >
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      size='large'
                      type='submit'
                      variant='contained'
                      sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px', mt: 4 }}
                    >
                      Login
                    </LoadingButton>
                  </form>
                  <Box mt={2}>
                    <Typography
                      variant='body2'
                      onClick={handleClick}
                      sx={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' }}
                    >
                      Forgot Your Password?
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default LoginForm
