// export const API_BASE_URL = 'http://localhost:8000'
// export const API_BASE_URL = 'http://3.141.192.236:8000'
export const API_BASE_URL = 'https://api.cruizewatersport.ai'

export const ApiEndPoints = {
  AUTH: {
    verifyEmail: `${API_BASE_URL}/api/v1/auth/user/verifyEmail`,
    login: `${API_BASE_URL}/api/v1/auth/user/login`,
    register: `${API_BASE_URL}/api/v1/auth/user/register`,
    forgetpassword: `${API_BASE_URL}/api/v1/auth/user/forgot-password`,
    verifyOtp: `${API_BASE_URL}/api/v1/auth/user/otp-verification`,
    resetPassword: `${API_BASE_URL}/api/v1/auth/user/reset-password`,
    resendOtp: `${API_BASE_URL}/api/v1/auth/user/resend-otp`,
    socialLogin: `${API_BASE_URL}/api/v1/auth/user/social-login`
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/settings/dashboard`
  },
  MEDICAL_SPECIALIZATION: {
    list: `${API_BASE_URL}/api/v1/medical-specialization`,
    create: `${API_BASE_URL}/api/v1/medical-specialization`,
    edit: id => `${API_BASE_URL}/api/v1/medical-specialization/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/medical-specialization/${id}`
  },
  PLAN_SERVICES: {
    list: `${API_BASE_URL}/api/v1/plan-services/search`,
    create: `${API_BASE_URL}/api/v1/plan-services`,
    edit: id => `${API_BASE_URL}/api/v1/plan-services/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/plan-services/${id}`
  },
  PLAN_PACKAGES: {
    list: `${API_BASE_URL}/api/v1/package/search`,
    create: `${API_BASE_URL}/api/v1/package`,
    edit: id => `${API_BASE_URL}/api/v1/package/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/package/${id}`
  },
  PLAN: {
    list: `${API_BASE_URL}/api/v1/plan/search`,
    create: `${API_BASE_URL}/api/v1/plan`,
    edit: id => `${API_BASE_URL}/api/v1/plan/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/plan/${id}`,
    listById: id => `${API_BASE_URL}/api/v1/plan/${id}`
  },
  SUBSCRIPTION_PLAN_SERVICES: {
    create: id => `${API_BASE_URL}/api/v1/plan/${id}/add-service`,
    delete: (id, plan_id) => `${API_BASE_URL}/api/v1/plan/${plan_id}/delete-service/${id}`
  },
  SUBSCRIPTION_PLAN_PACKAGES: {
    create: id => `${API_BASE_URL}/api/v1/plan/${id}/package`,
    delete: (id, plan_id) => `${API_BASE_URL}/api/v1/plan/${plan_id}/package/${id}`
  },
  BULLET_PLAN: {
    create: id => `${API_BASE_URL}/api/v1/plan/${id}/bullet-plan`,
    delete: id => `${API_BASE_URL}/api/v1/plan/${id}/bullet-plan`
  },
  EXTRA_SERVICES: {
    create: plan_id => `${API_BASE_URL}/api/v1/plan/${plan_id}/extra-service`,
    delete: plan_id => `${API_BASE_URL}/api/v1/plan/${plan_id}/extra-service`,
    edit: (id, plan_id) => `${API_BASE_URL}/api/v1/plan/${plan_id}/extra-service/service/${id}`,
    deleteById: (id, plan_id) => `${API_BASE_URL}/api/v1/plan/${plan_id}/extra-service/service/${id}`
  },
  // SETTINGS: {
  //   list: `${API_BASE_URL}/api/v1/settings`,
  //   edit: `${API_BASE_URL}/api/v1/settings`
  // },
  PROVIDER: {
    list: `${API_BASE_URL}/api/v1/provider/search`,
    edit: id => `${API_BASE_URL}/api/v1/provider/${id}`,
    passwordEdit: id => `${API_BASE_URL}/api/v1/provider/${id}/reset-password`,
    listById: id => `${API_BASE_URL}/api/v1/provider/${id}`
  },
  PROVIDER_TYPE: {
    create: `${API_BASE_URL}/api/v1/provider/type`,
    list: `${API_BASE_URL}/api/v1/provider/type`,
    delete: id => `${API_BASE_URL}/api/v1/provider/type/${id}`,
    edit: id => `${API_BASE_URL}/api/v1/provider/type/${id}`,
    listById: id => `${API_BASE_URL}/api/v1/provider/type/${id}`
  },
  PROVIDER_SUBTYPE: {
    create: id => `${API_BASE_URL}/api/v1/provider/sub-type/type/${id}`,
    delete: (id, provider_type_id) => `${API_BASE_URL}/api/v1/provider/type/${provider_type_id}/sub_type/${id}`
  },
  AREA_OF_SPECIALITY: {
    create: id => `${API_BASE_URL}/api/v1/provider-specialty/type/${id}`,
    delete: (id, provider_type_id) =>
      `${API_BASE_URL}/api/v1/provider-specialty/provider-type/${provider_type_id}/specialty/${id}`
  },
  MEMBER: {
    list: `${API_BASE_URL}/api/v1/member`,
    edit: id => `${API_BASE_URL}/api/v1/member/${id}`,
    passwordEdit: id => `${API_BASE_URL}/api/v1/member/${id}/reset-password`,
    listById: id => `${API_BASE_URL}/api/v1/member/${id}`
  },
  PLAN_OWNER: {
    list: `${API_BASE_URL}/api/v1/plan-owner`,
    edit: id => `${API_BASE_URL}/api/v1/plan-owner/${id}`,
    passwordEdit: id => `${API_BASE_URL}/api/v1/plan-owner/${id}/reset-password`,
    listById: id => `${API_BASE_URL}/api/v1/plan-owner/${id}`
  }
}
