// src/context/AuthContext.js

import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import authConfig from '../configs/auth'
import FallbackSpinner from 'src/@core/components/spinner'
import { ApiEndPoints } from 'src/network/endpoints'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const navigate = useNavigate()

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
      let storedUser = null

      try {
        // Ensure token is valid
        if (storedToken) {
          // Retrieve and parse user data from localStorage
          const userData = window.localStorage.getItem(authConfig.storageUserDataKeyName)
          if (userData) {
            try {
              storedUser = JSON.parse(userData)
            } catch (error) {
              console.error('Error parsing user data from localStorage:', error)
              window.localStorage.removeItem(authConfig.storageUserDataKeyName)
              window.localStorage.removeItem(authConfig.storageTokenKeyName)
            }
          }
          if (storedUser) {
            setUser(storedUser)
            setLoading(false)
          } else {
            setLoading(true)
            try {
              const response = await axios.get(ApiEndPoints.AUTH.me, {
                headers: {
                  Authorization: `Bearer ${storedToken}`
                }
              })
              storedUser = response.data.user
              window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(storedUser))
              setUser(storedUser)
            } catch (error) {
              console.error('Failed to fetch user data:', error)
              window.localStorage.removeItem(authConfig.storageUserDataKeyName)
              window.localStorage.removeItem(authConfig.storageTokenKeyName)
              setUser(null)
            }
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error('Unexpected error in initAuth:', error)
        window.localStorage.removeItem(authConfig.storageUserDataKeyName)
        window.localStorage.removeItem(authConfig.storageTokenKeyName)
        setUser(null)
        setLoading(false)
      }
    }
    initAuth()
  }, [])

  const handleLogin = data => {
    if (!data || !data.token || !data.user) {
      console.error('Invalid login response data:', data)
      return
    }

    const { token, user } = data
    window.localStorage.setItem(authConfig.storageTokenKeyName, token)
    window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
    setUser(user)
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem(authConfig.storageUserDataKeyName)
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    navigate('/landing-page')
  }

  const handleRegister = () => {}

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister
  }

  return <AuthContext.Provider value={values}>{loading ? <FallbackSpinner /> : children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
